const maxlength = (value = "", max = 0, message = null) => {
  if ((value || "").length <= max) {
    return true;
  } else {
    return message ? message : `El campo debe tener ${max} caracteres o menos`;
  }
};
const isRequired = (value, message = null) => {
  if (value) {
    return true;
  } else {
    return message ? message : "Campo requerido";
  }
};
const isUrl = (value, message = null) => {
  let regex =
    //eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  if (regex.test(value)) {
    return true;
  } else {
    return message ? message : "No es una URL válida";
  }
};
export { maxlength, isRequired, isUrl };
