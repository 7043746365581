var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"hidden-md-and-up"},[_c('h1',{staticClass:"title font-weight-medium"},[_vm._v("Aplicaciones")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('label',{staticClass:"align-center",attrs:{"for":"page-select"}},[_vm._v("Resultados:")])]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.rowsPerPage,"item-text":"value","item-value":"value","label":"Resultados por página","hide-details":"","solo":""},on:{"change":_vm.listenPagination},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"auto"}},[_c('create-application-dialog',{on:{"resetFilter":_vm.resetFilter}})],1)],1),_c('edit-application-dialog',{attrs:{"application":_vm.editedItem},on:{"resetFilter":_vm.resetFilter}}),_c('delete-application-dialog',{attrs:{"application":_vm.deletedItem}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.applications,"items-per-page":-1,"loading":_vm.isLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 pt-6",attrs:{"append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',{staticClass:"text-md-body-1"},[_vm._v(" mdi-open-in-new ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"text-md-body-1",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.active ? 'green' : 'grey lighten-1',"dark":"","small":""}},[_vm._v(" "+_vm._s(item.active ? "Activo" : "Inactivo")+" ")])]}}],null,true)}),(_vm.paginationPages > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.paginationPages},on:{"input":function($event){return _vm.listenPagination()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }